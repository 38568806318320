<mat-sidenav-container>
  <mat-sidenav [fixedInViewport]="true" fixedTopGap="86" [opened]="false">
    Foo
  </mat-sidenav>
  <mat-sidenav-content>
    <!--    <ec-desktop-app-header *ngIf="isDesktopWidth()"></ec-desktop-app-header>-->
    <!--    <ec-mobile-app-header *ngIf="!isDesktopWidth()"></ec-mobile-app-header>-->
    <ec-mobile-app-header></ec-mobile-app-header>
    <div class="app-content" [style.top.px]="iOSCalc().contentTopPx">
      <router-outlet></router-outlet>
    </div>
    <!--    <qu-mobile-app-footer *ngIf="showFooter()"></qu-mobile-app-footer>-->
  </mat-sidenav-content>
</mat-sidenav-container>
