
import {BuildVersionUi} from '@gigasoftware/shared/models';

/**
* Generated by script tools/bump.version.js
*
* See docs/VERSION.md
*
* To update version:
* 1. Make sure repo is clean ( everything committed )
* 2. Edit the ./assets/app-version.json
* 3. Run yarn bump.version
*
*/

/**
* UI
*/
export const appInstalledBuildVersion: BuildVersionUi = {
  angularVersion: '18.1.1',
  app: 'ec-app',
  buildNumber: 7,
  changeLog: '',
  month: '8',
  releaseType: 'release',
  year: 2024,
};

/**
* Build
*/
export const quizKitBuild = `2024.8.7`;

/**
* Angular Version
*/
export const quizKitAngularVersion = `18.1.1`;

/**
* Build Type
*/
export const releaseType = "release"
  