<ec-logo [height]="30" (clickAction)="navigateStart()"></ec-logo>

<nav mat-tab-nav-bar *ngIf="appService.isLoggedIn()" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    *ngFor="let link of links"
    [routerLink]="link.link"
    (click)="activeLink = link.link"
    [active]="activeLink === link.link">
    {{ link.label }}
  </a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

<div class="flex-1"></div>

<button
  mat-flat-button
  color="accent"
  class="p-mr-10"
  *ngIf="notHasActiveSubscription()"
  (click)="subscribe()">
  Subscribe
</button>

<button
  class="p-mr-10 ec-alert-theme"
  mat-icon-button
  [disabled]="hasNoNotifications()"
  [matMenuTriggerFor]="notificationsPopover">
  <mat-icon [matBadge]="notificationTotal()">notifications</mat-icon>
</button>

<button
  *ngIf="!appService.isLoggedIn()"
  [disabled]="!appService.isLoggedIn()"
  class="p-user-button"
  mat-icon-button
  aria-label="icon-button with share icon">
  <mat-icon>account_circle</mat-icon>
</button>

<button
  *ngIf="appService.isLoggedIn()"
  [matMenuTriggerFor]="accountMenu"
  class="p-user-button--logged-in"
  mat-icon-button
  aria-label="icon-button with share icon">
  <span *ngIf="!appService.userImage().isPhotoURL" dlcRoundedLetter>
    {{ appService.userImage().character }}
  </span>

  <span *ngIf="appService.userImage().isPhotoURL" class="photo-url">
    <img [src]="appService.userImage().photoURL" alt="{{ appService.userImage().name }}" />
  </span>

  <!--      <span class="user-name">{{ userImage.name }}</span>-->
</button>

<mat-menu #notificationsPopover>
  <dlc-notifications (notificationAction)="notificationAction($event)"></dlc-notifications>
</mat-menu>

<mat-menu #accountMenu="matMenu">
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.DASHBOARD_PROFILE_PAGE)"
    *ngIf="appService.isLoggedIn()">
    <mat-icon>account_circle</mat-icon>
    <span>Profile</span>
  </button>
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.DASHBOARD_ACCOUNT_PAGE)"
    *ngIf="appService.isLoggedIn()">
    <mat-icon>manage_accounts</mat-icon>
    <span>Account</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.DASHBOARD_SUPPORT_PAGE)">
    <mat-icon>support</mat-icon>
    <span>Support</span>
  </button>
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.DASHBOARD_PRIVACY_POLICY_PAGE)">
    <mat-icon>security</mat-icon>
    <span>Privacy Policy</span>
  </button>
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.DASHBOARD_TERMS_OF_USE_PAGE)">
    <mat-icon>policy</mat-icon>
    <span>Terms Of Use</span>
  </button>
  <button mat-menu-item (click)="onAbout()">
    <mat-icon>info</mat-icon>
    <span>About</span>
  </button>
  <button mat-menu-item (click)="sw.checkForUpdate()">
    <mat-icon>download</mat-icon>
    <span>Check For Updates</span>
  </button>
  <button mat-menu-item (click)="onLogout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<mat-progress-bar
  *ngIf="sw.updateProgress()"
  color="accent"
  mode="indeterminate"></mat-progress-bar>
