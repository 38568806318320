<ec-logo
  class="mr-2"
  [height]="32"
  [width]="42"
  (clickAction)="urlRouteParams.navigateWithParams(nav.MOBILE_HOME_PAGE)"></ec-logo>

<div *ngIf="parent()?.showNav">
  <button mat-stroked-button (click)="urlRouteParams.navigateWithParams(parent().nav.id)">
    <mat-icon>arrow_back_ios</mat-icon>
    <span>{{ parent().nav.name }}</span>
  </button>
</div>

<div class="flex-1"></div>

<button
  class="ml-2 mr-2 ec-alert-theme"
  *ngIf="hasNotifications()"
  mat-icon-button
  [matMenuTriggerFor]="notificationsPopover">
  <mat-icon [matBadge]="notificationTotal()" aria-hidden="false">notifications</mat-icon>
</button>

<!-- SUBSCRIBE -->
<button
  *ngIf="notHasActiveSubscription()"
  dlc-rounded-text-icon-button
  color="accent"
  (click)="subscribe()">
  <mat-icon>key</mat-icon>
  Subscribe
</button>

<button
  *ngIf="!appService.isLoggedIn()"
  dlc-rounded-text-icon-button
  class="ml-2"
  (click)="onLogin()"
  color="primary">
  <mat-icon> login </mat-icon>
  Login
</button>

@if (appService.isLoggedIn()) {
  <button
    [matMenuTriggerFor]="accountMenu"
    class="flex flex-row justify-center items-center mr-2"
    mat-icon-button
    aria-label="icon-button with share icon">
    <mat-icon>menu</mat-icon>
  </button>
} @else {
  <button
    class="ml-2"
    [matMenuTriggerFor]="loggedOffMenu"
    mat-icon-button
    aria-label="icon-button with share icon">
    <mat-icon>menu</mat-icon>
  </button>
}

<mat-menu #notificationsPopover>
  <dlc-notifications (notificationAction)="notificationAction($event)"></dlc-notifications>
</mat-menu>

<mat-menu #accountMenu="matMenu">
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.MOBILE_PROFILE_PAGE)"
    *ngIf="appService.isLoggedIn()">
    <mat-icon>account_circle</mat-icon>
    <span>Profile</span>
  </button>
  <button
    mat-menu-item
    (click)="urlRouteParams.navigateWithParams(nav.MOBILE_ACCOUNT_PAGE)"
    *ngIf="appService.isLoggedIn()">
    <mat-icon>manage_accounts</mat-icon>
    <span>Account</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_SUPPORT_PAGE)">
    <mat-icon>support</mat-icon>
    <span>Support</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_PRIVACY_POLICY_PAGE)">
    <mat-icon>security</mat-icon>
    <span>Privacy Policy</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_TERMS_OF_USE_PAGE)">
    <mat-icon>policy</mat-icon>
    <span>Terms Of Use</span>
  </button>
  <button mat-menu-item (click)="onAbout()">
    <mat-icon>info</mat-icon>
    <span>About</span>
  </button>
  @if (selectLoggedInUserIsEcAdminOrRoot()) {
    <button mat-menu-item (click)="adminSubjects()">
      <mat-icon>subject</mat-icon>
      <span>Admin Subjects</span>
    </button>
    <button mat-menu-item (click)="adminUsers()">
      <mat-icon>
        <span class="material-symbols-outlined">group</span>
      </mat-icon>
      <span>Admin Users</span>
    </button>
  }
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_SUBSCRIBE_PAGE)">
    <mat-icon>subscriptions</mat-icon>
    <span>Subscription</span>
  </button>
  <button mat-menu-item (click)="sw.checkForUpdate()">
    <mat-icon>download</mat-icon>
    <span>Check For Updates</span>
  </button>
  <button mat-menu-item (click)="onLogout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<mat-menu #loggedOffMenu="matMenu">
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_SUPPORT_PAGE)">
    <mat-icon>support</mat-icon>
    <span>Support</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_PRIVACY_POLICY_PAGE)">
    <mat-icon>security</mat-icon>
    <span>Privacy Policy</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_TERMS_OF_USE_PAGE)">
    <mat-icon>policy</mat-icon>
    <span>Terms Of Use</span>
  </button>
  <button mat-menu-item (click)="onAbout()">
    <mat-icon>info</mat-icon>
    <span>About</span>
  </button>
  <button mat-menu-item (click)="sw.checkForUpdate()">
    <mat-icon>download</mat-icon>
    <span>Check For Updates</span>
  </button>
  <button mat-menu-item (click)="urlRouteParams.navigateWithParams(nav.MOBILE_SUBSCRIBE_PAGE)">
    <mat-icon>subscriptions</mat-icon>
    <span>Prices</span>
  </button>
</mat-menu>

<mat-progress-bar
  class="!absolute bottom-[-4px] left-0 right-0"
  *ngIf="sw.updateProgress()"
  color="accent"
  mode="indeterminate"></mat-progress-bar>
