<mat-dialog-content class="mt-10">
  <button class="!absolute top-0 right-0" dlc-rounded-icon-button mat-dialog-close="true">
    <mat-icon>close</mat-icon>
  </button>

  <div class="dialog-content">
    <ec-logo class="mt-8" color="primary" [width]="250"></ec-logo>
    <div class="mt-8 mat-caption">
      <div class="flex flex-row justify-start items-center">
        <span>{{ version }}</span>
        <button class="ml-5" mat-icon-button (click)="copyVersion()">
          <mat-icon>content_copy</mat-icon>
        </button>
        <div
          class="invisible"
          #copyTooltip="matTooltip"
          matTooltip="Copied!"
          matTooltipPosition="right"></div>
      </div>
    </div>
    <div class="mt-8 mat-caption">
      © {{ appVersion.version.year }} GiGaSoftware Inc. All rights reserved. Proprietary Software.
    </div>
  </div>
</mat-dialog-content>
