import {NAV, navItemDict} from '@gigasoftware/evolving-cognition/domain';
import {NavItem} from '@gigasoftware/shared/domain';

export interface HeaderNav {
  nav: NavItem;
  showLogo: boolean;
  showNav: boolean;
}

export function configureHeaderNav(nav: NavItem | null, parent: NavItem | null): HeaderNav {
  const home = navItemDict[NAV.MOBILE_HOME_PAGE];
  const login = navItemDict[NAV.MOBILE_LOGIN_PAGE];

  if (nav && nav.url === home.url) {
    return <HeaderNav>{
      nav: home,
      showLogo: true,
      showNav: false
    };
  } else if (nav && nav.url === login.url) {
    return <HeaderNav>{
      nav: home,
      showLogo: false,
      showNav: false
    };
  } else if (!parent) {
    return <HeaderNav>{
      nav: home,
      showLogo: false,
      showNav: false
    };
  } else {
    return {
      nav: parent,
      showLogo: false,
      showNav: true
    };
  }
}
