import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabNav, MatTabsModule} from '@angular/material/tabs';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {
  AppService,
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  getNavItemUrl,
  NAV,
  PlatSwUpdateService
} from '@gigasoftware/evolving-cognition/domain';
import {EcLogoComponent} from '@gigasoftware/evolving-cognition/ui';
import {navigateWithParams, UrlParamsRouteService} from '@gigasoftware/shared/domain';
import {
  DlcRoundedLetterComponent,
  NotificationsComponent
} from '@gigasoftware/shared/ui-design-library';
import {
  DEFAULT_NOTIFICATION,
  selectHasNoNotifications,
  selectNgPatIsLoggedIn,
  selectNgPatNotHasActiveSubscription,
  selectNotificationTotal,
  UiNotification
} from '@ngpat/store';
import {WINDOW} from '@ngpat/utils';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {AboutDialogComponent} from '../../about-dialog/about-dialog.component';

@Component({
  selector: 'ec-desktop-app-header',
  templateUrl: './desktop-app-header.component.html',
  styleUrls: ['./desktop-app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'qu-desktop-app-header mat-elevation-z2',
    '[class.ios-notch]': 'isIOS'
  },
  standalone: true,
  imports: [
    CommonModule,
    EcLogoComponent,
    MatTabsModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    NotificationsComponent,
    MatProgressBarModule,
    DlcRoundedLetterComponent
  ],
  providers: []
})
export class DesktopAppHeaderComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  nav = NAV;

  links = [
    {
      link: getNavItemUrl(NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE),
      label: 'Personal Quizzes'
    },
    {
      link: getNavItemUrl(NAV.DASHBOARD_STUDY_GROUPS_PAGE),
      label: 'Study Groups'
    },
    {
      link: getNavItemUrl(NAV.DASHBOARD_CLASSES_PAGE),
      label: 'Classes'
    }
  ];
  activeLink = this.links[0].link;
  tabIndex = 0;

  notificationTotal: Signal<number> = this.store.selectSignal(selectNotificationTotal);
  hasNoNotifications: Signal<boolean> = this.store.selectSignal(selectHasNoNotifications);

  notHasActiveSubscription: Signal<boolean> = this.store.selectSignal(
    selectNgPatNotHasActiveSubscription
  );
  get isIOS() {
    return Capacitor.getPlatform() === 'ios';
  }

  @ViewChild(MatTabNav) public matTabNav: MatTabNav | null = null;

  constructor(
    public sw: PlatSwUpdateService,
    public appService: AppService,
    public urlRouteParams: UrlParamsRouteService,
    private store: Store,
    private _dialog: MatDialog,
    private _zone: NgZone,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    @Inject(WINDOW) private _win: Window,
    @Inject(EC_ENVIRONMENT) private _env: EvolutionCognitionEnvironment
  ) {}

  ngOnInit(): void {
    this._router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((n: NavigationEnd) => {
        if (this.matTabNav) {
          if (n.url.includes('personal')) {
            this.matTabNav.selectedIndex = 0;
          } else if (n.url.includes('study')) {
            this.matTabNav.selectedIndex = 1;
          } else {
            this.matTabNav.selectedIndex = 2;
          }
        }

        this._cd.detectChanges();
      });

    this._cd.detectChanges();
  }

  /**
   * TODO Auto Update
   * @param n
   */
  notificationAction(n: UiNotification) {
    if (n.id === DEFAULT_NOTIFICATION.APP_UPDATE) {
      this.sw.checkForUpdate();
    }
  }

  onAbout() {
    this._dialog.open(AboutDialogComponent, {
      backdropClass: 'dlc-global-dialog-background'
    });
  }

  onLogout() {
    const that = this;
    this.appService.logout();

    this.store
      .select(selectNgPatIsLoggedIn)
      .pipe(
        filter(isLoggedIn => !isLoggedIn),
        take(1)
      )
      .subscribe(() => {
        that._zone.run(() => {
          that._router.navigate(['start']).then(() => {
            that._win.location.reload();
          });
        });
      });
  }

  navigateStart() {
    if (this.appService.isLoggedIn()) {
      this.store.dispatch(navigateWithParams(NAV.DASHBOARD_CLASSES_PAGE));
    } else {
      this.store.dispatch(navigateWithParams(NAV.MOBILE_HOME_PAGE));
    }
  }

  subscribe(): void {
    if (Capacitor.isNativePlatform()) {
      this.store.dispatch(navigateWithParams(NAV.MOBILE_SUBSCRIBE_PAGE));
    } else {
      this._win.open(`${this._env.deviceBuild.marketingSiteSubscribe}`);
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
